<template lang="html">
  <div id="faqs-page">

    <section class="container main-section">
      <div class="row">
        <div class="col-12 col-title">
          <h1 class="title-st-1">FAQ</h1>
        </div>

        <div class="col-lg-12 col-faqs">
          <div class="box-faqs" role="tablist">
            <b-card no-body class="mb-1" v-for="(qa, qaInx) in faqs" :key="'qaInx-'+qaInx">
              <b-card-header header-tag="header" role="tab">
                <a v-b-toggle="'accordion-'+qaInx" class="t-150 question">{{ qa.q }}</a>
              </b-card-header>

              <b-collapse :id="'accordion-'+qaInx" :visible="qa.show" :accordion="'my-accordion'-qa.rand" role="tabpanel">
                <b-card-body v-html="qa.a"></b-card-body>
              </b-collapse>
            </b-card>
          </div>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
export default {
  data(){
    return{
      faqs: [
        {
          show: false, q: 'Is 1 Stop registered with my state licensing division?',
          rand: (Math.random() * 10) + 1,
          a: `<p>
              Yes! All courses offered on our site are registered with the State Board to which they pertain!
            </p>
          `
        },
        {
          show: false, q: 'How soon will I receive credits for the course I complete?',
          rand: (Math.random() * 10) + 1,
          a: `<p>
              You will receive a certificate of completion immediately after completing your course. We will submit your course completion information to your state licensing division within 24 hours of your completion.
            </p>
          `
        },
        {
          show: false, q: 'Can I pause my selected course and complete it at a later time after it has been launched?',
          rand: (Math.random() * 10) + 1,
          a: `<p>
              Yes! Your course progress is saved anytime you log out. You can come back anytime and begin right where you left off!
            </p>
          `
        },
        {
          show: false, q: 'What type of payment does 1 Stop accept?',
          rand: (Math.random() * 10) + 1,
          a: `<p>
              We accept all major credit and debit cards as well as electronic checks and PayPal.
            </p>
          `
        },
        {
          show: false, q: 'Is 1 Stop easy to use?',
          rand: (Math.random() * 10) + 1,
          a: `<p>
              Yes! We have the easiest user interface available on the web.
            </p>
          `
        },
        {
          show: false, q: 'What is the refund policy?',
          rand: (Math.random() * 10) + 1,
          a: `<p>
               Due to copyright issues, we are only able to issue refunds on a case by case basis. Contact our customer support at <a href="mailto:info@1stoprenewal.com">info@1stoprenewal.com</a>.
            </p>
          `
        },
        {
          show: false, q: "I can't find what I need?",
          rand: (Math.random() * 10) + 1,
          a: `<p>
              Contact us at <a href="mailto:info@1stoprenewal.com">info@1stoprenewal.com</a>
            </p>
          `
        },
      ]
    }
  }
}
</script>
